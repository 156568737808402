<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <h1 class="title has-text-centered">
          Thank you!
        </h1>
        <p class="subtitle has-text-centered section">
          🚀 It's time to take your tiktok marketing strategy to the next level 🚀
        </p>
        <p class="subtitle has-text-centered">
          Where would you like to start?
        </p>
        <div class="columns is-centered">
          <div class="column has-text-centered is-2">
            <b-button size="is-medium is-primary" tag="router-link" to="/new/user" expanded>
              Track a user
            </b-button>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column has-text-centered is-2">
            <b-button size="is-medium is-primary" tag="router-link" to="/new/hashtag" expanded>
              Track a hashtag
            </b-button>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column has-text-centered is-2">
            <b-button size="is-medium is-primary" tag="router-link" to="/new/music" expanded>
              Track a song
            </b-button>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column has-text-centered is-2">
            <b-button size="is-medium is-primary" tag="router-link" to="/new/post" expanded>
              Track a post
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  mounted() {
    // get result for data
    this.$amplitude.logEvent("user has paid subscription");
    this.$store.dispatch("user/getUser");
  },
};
</script>
